import { Modal } from './Modal.component';
import React, { forwardRef, RefForwardingComponent, useImperativeHandle, useState } from 'react';
import { GenericModalProps, ModalProps } from './Modal.types';

const GenericModal: RefForwardingComponent<GenericModalProps> = (_, modalRef) => {
  const close = () => {};
  const [modalProps, setModalProps] = useState<ModalProps>({ close, visible: false });

  function show(props: Partial<ModalProps>) {
    return setModalProps({ close, ...props, visible: true });
  }

  function hide() {
    return setModalProps({ ...modalProps, visible: false });
  }

  function onClose() {
    if (modalProps.close) {
      modalProps.close();
    }
    hide();
  }

  useImperativeHandle(modalRef, () => ({
    hide,
    show,
  }));

  return <Modal {...modalProps} close={onClose} />;
};

export default forwardRef(GenericModal);
