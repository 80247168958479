/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import debounce from 'lodash/debounce';
import { ActivityCode, InterestCode } from 'pleinchamp-api-client/dist/src/content/content';
import { ProfessionCode } from 'pleinchamp-api-client/dist/src/user/user';
import { isServer } from '@utils/server';
import { ADVERT_FORMAT_ID, ADVERT_SITE_ID } from './Adverts.contants';

export interface AdvertLib {
  cmd: {
    push: (cb: () => void) => void;
  };
  enableLazyLoading: (options: {
    fetchRatio: number;
    mobileFetchRatio: number;
    formats: [ADVERT_FORMAT_ID];
  }) => () => void;
  setup: (options: { async?: boolean; domain: string; networkid: number }) => void;
  call: (
    type: 'onecall' | 'std',
    options: {
      formatId?: ADVERT_FORMAT_ID;
      target?: string;
      pageId?: string | number;
      siteId: string | number;
      formats?: Array<{ id: ADVERT_FORMAT_ID }>;
    }
  ) => void;
  renderMode: {
    DEFAULT: 0;
    READY: 1;
    ON_DEMAND: 2;
  };
  render: (formatId: ADVERT_FORMAT_ID | string) => void;
  cleanAds: () => void;
  clean: (formatId?: ADVERT_FORMAT_ID) => void;
  setGdprConsentData: (iabConsentString: string) => void;
}

const START_TIMEOUT = 500;

class Format {
  public id: ADVERT_FORMAT_ID;

  public isRendered: boolean;

  constructor(formatId: ADVERT_FORMAT_ID, isRendered = false) {
    this.id = formatId;
    this.isRendered = isRendered;
  }
}

class AdverServ {
  private isInit = false;

  private siteId = ADVERT_SITE_ID;

  private target?: string;

  private startTriesTimeout?: number;

  public formats: Format[] = [];

  public pageId?: string | number;

  private isStarted = false;

  private sas?: AdvertLib;

  public isMobile?: boolean;

  private shouldStart = false;

  private isCleaning = false;

  private get areAllFormatsRendered(): boolean {
    return this.formats.length > 0 && this.formats.every(format => format.isRendered);
  }

  init(
    professions: ProfessionCode[] = [],
    activities: ActivityCode[] = [],
    interests: InterestCode[] = [],
    isMobile: boolean
  ) {
    if (this.isInit || isServer() || !window.sas) {
      return;
    }
    this.sas = window.sas;
    this.isMobile = isMobile;

    this.setConsent();

    this.target = [
      ...professions.map(prof => `profession=${prof}`),
      ...activities.map(act => `activity=${act}`),
      ...interests.map(int => `interest=${int}`),
    ].join(';');

    this.sas.cmd.push(() => {
      this.sas?.setup({
        async: true,
        domain: '//www14.smartadserver.com',
        networkid: 3053,
      });
    });
    this.isInit = true;
  }

  public setConsent() {
    if (!this.sas || !TagCommanderService.getIABConsentString()) {
      return;
    }
    this.sas.setGdprConsentData(TagCommanderService.getIABConsentString());
  }

  // Initialize advert is advert has been registered in formats array
  private callStandard(formatId: ADVERT_FORMAT_ID) {
    if (this.isMobile && formatId === ADVERT_FORMAT_ID.Arch) {
      return;
    }
    this.sas?.cmd.push(() => {
      this.sas?.call('std', {
        formatId,
        pageId: this.pageId,
        siteId: this.siteId,
        target: window.CookieConsent?.consent.marketing ? this.target : undefined,
      });
    });
    this.sas?.cmd.push(() => {
      this.sas?.enableLazyLoading({
        fetchRatio: 0.3,
        formats: [formatId],
        mobileFetchRatio: 1.1,
      });
    });
  }

  // ⚠️ WARNING, can be slower than start
  clean(formatId?: ADVERT_FORMAT_ID) {
    this.isCleaning = true;
    this.formats = [];
    this.isStarted = false;
    this.sas?.clean(formatId);
    this.isCleaning = false;

    if (this.shouldStart) {
      this.start();
      this.shouldStart = false;
    }
  }

  cleanAll() {
    this.formats.forEach(f => {
      this.sas?.clean(f.id);
    });
    this.isCleaning = true;
    this.formats = [];
    this.isStarted = false;
    this.isCleaning = false;
    this.start();
    this.shouldStart = false;
  }

  reRender() {
    this.formats.forEach(f => {
      this.render(f.id);
    });
  }

  private startAll() {
    if (this.isStarted) {
      return;
    }
    this.isStarted = true;
    // this.oneCall();
    this.formats.forEach(f => {
      this.callStandard(f.id);
    });
  }

  start() {
    window.setTimeout(() => {
      if (this.isCleaning) {
        this.shouldStart = true;
        return;
      }
      if (this.pageId && this.areAllFormatsRendered) {
        clearTimeout(this.startTriesTimeout);
        this.startAll();
        return;
      }
      this.startTriesTimeout = window.setTimeout(() => this.debouncedStart(), 2000);
    });
  }

  debouncedStart = debounce(() => this.start(), START_TIMEOUT);

  render(formatId: ADVERT_FORMAT_ID) {
    window.setTimeout(() => {
      if (this.isCleaning) {
        setTimeout(() => this.render(formatId));
        return;
      }
      this.sas?.cmd.push(() => {
        this.sas?.render(formatId);
      });
      this.isStarted = false;
      const format = this.formats.find(f => f.id === formatId);

      if (format) {
        format.isRendered = true;
      } else {
        this.formats.push(new Format(formatId, true));
      }

      if (this.isStarted) {
        this.callStandard(formatId);
      } else {
        this.debouncedStart();
      }
    });
  }
}

export const AdvertService = new AdverServ();
